export type DashboardElement =
  | 'core-value-1'
  | 'core-value-2'
  | keyof typeof DashboardElement.BEST_PRACTICE.values
  | keyof typeof DashboardElement.LEARNING_POTENTIAL.values
  | keyof typeof DashboardElement.FILTER.values;

export namespace DashboardElement {
  export const QUESTION_TYPE = {
    title: 'block-dashboard-element-qt',
    values: {
      'core-value-1': 'block-dashboard-element-core-value-1',
      'core-value-2': 'block-dashboard-element-core-value-2',
      'products-services-1': 'block-dashboard-element-products-services-1',
      'products-services-2': 'block-dashboard-element-products-services-2',
      enps: 'block-dashboard-element-enps',
    },
  };

  export const BEST_PRACTICE = {
    title: 'block-dashboard-element-bp',
    values: {
      grade: 'block-dashboard-element-grade',
      'strategic-values': 'block-dashboard-element-strategic-values',
      continuity: 'block-dashboard-element-continuity',
    },
  };

  export const LEARNING_POTENTIAL = {
    title: 'block-dashboard-element-lp',
    values: {
      'missing-values': 'block-dashboard-element-missing-values',
      'subject-importance': 'block-dashboard-element-subject-importance',
      'subject-satisfaction': 'block-dashboard-element-subject-satisfaction',
      participation: 'block-dashboard-element-participation',
    },
  };

  export const FILTER = {
    title: 'block-dashboard-element-fl',
    values: {
      'question-gender': 'block-dashboard-element-question-gender',
      'question-age': 'block-dashboard-element-question-age',
      'question-region': 'block-dashboard-element-question-region',
      'question-country-of-birth':
        'block-dashboard-element-question-country-of-birth',
      'question-educational-attainment':
        'block-dashboard-element-question-educational-attainment',
      'question-contract-duration':
        'block-dashboard-element-question-contract-duration',
    },
  };
}
