

























































import { BceFile, debounce } from '@bcase/core';
import * as BCE from '@bcase/module-editor';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import DialogDashboardElement from '../../components/3-dialogs/dialog-dashboard-element.vue';
import { ModuleModule } from '../../store/modules/module-module';
import { StorageModule } from '../../store/modules/storage-module';
import { DashboardElement } from '../../data/dashboard-element';

@Component({
  components: { DialogDashboardElement },
})
export default class EditorBlock extends Vue {
  private editor = getModule(ModuleModule);
  private storage = getModule(StorageModule);

  public preview = false;
  public update = debounce(this.editor.updateBlock.bind(this.editor), 2000);

  public dialog: BCE.BlockElement | null = null;

  public get params() {
    const [mid, mversion] = this.$route.params.module.split('@');
    const [bid, bversion] = this.$route.params.block.split('@');

    return {
      module: { id: mid, version: mversion },
      block: { id: bid, version: bversion },
    };
  }

  public get block() {
    return this.editor.block;
  }

  public get blocks() {
    return this.editor.blocks;
  }

  public get module() {
    return this.editor.current;
  }

  public get nr() {
    return (
      this.module &&
      this.block &&
      new BCE.FlowTree(this.module.flow).getLabel(
        this.block.id + '@' + this.block.version
      )
    );
  }

  public get languages() {
    return this.$FEATURES.languages.map(key => ({
      key,
      label: this.$t('language-' + key),
      flag: `/img/${key}.svg`,
    }));
  }

  public get existing() {
    return this.editor.blocks.map(b => b.id);
  }

  async mounted() {
    await Promise.all([
      this.editor.load(this.params),
      this.storage.bind(this.$route.params.module),
    ]);

    this.watchBlock(this.block);
    this.watchModule(this.module);
    this.initialize();
  }

  public actionCondition(el: BCE.BlockElement) {
    const questions: BCE.BlockElement.Type[] = [
      'checkbox',
      'radio',
      'slider',
      'icon',
      'input',
      'matrix',
    ];

    return questions.indexOf(el.type) >= 0;
  }

  public actionSubmit(dashboard: DashboardElement) {
    if (!this.dialog) return;

    const editor = this.$refs['block-editor'] as any;
    editor.metadata(this.dialog.id, 'dashboard', dashboard);
    this.dialog = null;
  }

  @Watch('block')
  public watchBlock(value?: BCE.Block) {
    const editor = this.$refs['block-editor'] as HTMLBceBlockEditorElement;
    const flow = this.$refs['flow-editor'] as HTMLBceFlowEditorElement;

    editor.elementAction = [
      {
        icon: 'fas:chart-line',
        label: this.$t('block-dashboard-element'),
        condition: this.actionCondition,
        handler: el => (this.dialog = el),
      },
      {
        icon: 'fas:smile',
        label: this.$t('block-smiley-matrix'),
        condition: el => el.type === 'matrix',
        handler: (el: any, meta) => {
          const columns = [
            { icon: 'fas:sad-tear', text: 'fas:sad-tear', color: '#ef8b54' },
            {
              icon: 'fas:frown-open',
              text: 'fas:frown-open',
              color: '#f3ac4c',
            },
            { icon: 'fas:meh', text: 'fas:meh', color: '#f6d24c' },
            { icon: 'fas:grin', text: 'fas:grin', color: '#d7f18e' },
            { icon: 'fas:laugh', text: 'fas:laugh', color: '#aad77e' },
          ];

          const lang =
            this.$FEATURES.languages &&
            this.$FEATURES.languages
              .slice(1)
              .indexOf(meta.language || this.$FEATURES.languages[0]) >= 0
              ? meta.language
              : undefined;

          return lang
            ? {
                ...el,
                columns,
                i18n: {
                  ...(el.i18n || {}),
                  [lang]: { ...((el.i18n && el.i18n[lang]) || {}), columns },
                },
              }
            : { ...el, columns };
        },
      },
    ];

    editor.elementQuestion = {
      checkbox: this.getAxisQuestions(),
      icon: this.getAxisQuestions(),
      input: this.getAxisQuestions(),
      matrix: this.getAxisQuestions(),
      radio: this.getAxisQuestions(),
      slider: this.getAxisQuestions(),
    };

    editor.value = value
      ? { ...BCE.Block.empty(), ...value }
      : { ...BCE.Block.empty() };

    if (this.module) {
      editor.uploadMetadata = {
        ref: `module/${this.module.id}/{id}.{ext}`,
        group: `company-${this.module.id}`,
      };
    }

    flow.block = editor.value;
    flow.blocks = this.blocks;
  }

  @Watch('module')
  public watchModule(value?: BCE.Module) {
    const editor = this.$refs['flow-editor'] as HTMLBceFlowEditorElement;
    editor.value = value
      ? { ...BCE.ModuleFlow.empty(), ...value.flow }
      : { ...BCE.ModuleFlow.empty(), start: { id: '<start>', version: 'v1' } };
  }

  @Watch('$route')
  @Watch('existing')
  public initialize() {
    const editor = this.$refs['flow-editor'] as HTMLBceFlowEditorElement;
    editor.current = this.params.block.id;
    editor.currentVersion = this.params.block.version;
    editor.existing = this.existing;

    const selected =
      !!this.editor.selectedBlock &&
      this.params.block.id === this.editor.selectedBlock.id &&
      this.params.block.version === this.editor.selectedBlock.version;
    if (!selected) this.editor.SELECT_BLOCK(this.params.block);
  }

  public async remove(node: BCE.Reference) {
    const block = this.editor.blocks.find(b => {
      return b.id === node.id && b.version === node.version;
    });

    // Delete stored files
    if (block) {
      const { id, version } = this.module!;
      const group = `${id}@${version}`;

      BCE.storedFiles(block).map(id => this.storage.delete({ id, group }));
    }

    await this.editor.deleteBlock(node);

    // Navigate to start block if current block is deleted.
    if (this.module && node.id === this.params.block.id) {
      const { start } = this.module.flow;

      const module = this.params.module.id + '@' + this.params.module.version;
      const block = start.id + '@' + start.version;
      this.$router.push(`/editor/${module}/${block}`);
    }
  }

  public async navigate(node: BCE.Reference) {
    if (!this.module) return;

    const { id, version } = node;
    const module = this.params.module.id + '@' + this.params.module.version;

    // Skip if navigating to the current block
    if (this.block && this.block.id === id && this.block.version === version)
      return;

    // Check if block needs to be created
    const existing = this.editor.blocks.find(block => {
      return block.id === id && block.version === version;
    });

    // Create block if it doesn't exist
    if (!existing) {
      const created = await this.editor.createBlock(node);
      if (!created) throw new Error("Block couldn't be created");

      // Default to having a next block
      // const next = this.$firebase.generateId();
      // this.editor.updateFlow({
      //   ...this.module.flow,
      //   blocks: {
      //     ...this.module.flow.blocks,
      //     [node.id]: [{ id: next, version: 'v1' }]
      //   }
      // });
    }

    this.$router.push(`/editor/${module}/${id}@${version}`);
  }

  public complete() {
    this.$router.push('/editor');
  }

  public async handleFile(file: BceFile) {
    const { id, version } = this.module!;
    const group = `${id}@${version}`;
    const ref = `module/${id}/${this.block!.id}/${file.id}`;

    await this.storage.upload({ ref, group, file });
  }

  public async handleFileDelete(id: string) {
    const group = `${this.module!.id}@${this.module!.version}`;
    await this.storage.delete({ id, group });
  }

  private getAxisQuestions(): BCE.ElementQuestion[] {
    return [
      {
        key: 'x',
        label: 'Label x-as',
        value: this.getAxisQuestionValue.bind(this, true),
        onInput: this.getAxisQuestionHandler.bind(this, true),
      },
      {
        key: 'y',
        label: 'Label y-as',
        value: this.getAxisQuestionValue.bind(this, false),
        onInput: this.getAxisQuestionHandler.bind(this, false),
      },
    ];
  }

  private getAxisQuestionValue(x: boolean, el: BCE.BlockElement) {
    if (!this.block) return undefined;

    const axis = x ? 'Xaxis' : 'Yaxis';
    const { id } = el;

    const element = this.block.elements.find(el => el.id === id);
    return element ? element.metadata[axis] : undefined;
  }

  private getAxisQuestionHandler(
    x: boolean,
    el: BCE.BlockElement,
    event: Event
  ) {
    event.cancelBubble = true;

    const input = event.target as HTMLBceInputElement;
    if (!input) return;

    const axis = x ? 'Xaxis' : 'Yaxis';
    const { id } = el;

    const editor = this.$refs['block-editor'] as any;
    if (id && input.value !== undefined) editor.metadata(id, axis, input.value);
  }
}
