import { render, staticRenderFns } from "./editor-block.vue?vue&type=template&id=05655a5c&"
import script from "./editor-block.vue?vue&type=script&lang=ts&"
export * from "./editor-block.vue?vue&type=script&lang=ts&"
import style0 from "./editor-block.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports