
































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { DashboardElement } from '../../data/dashboard-element';

@Component
export default class DialogDashboardElement extends Vue {
  @Prop({ type: String, default: '' })
  public question!: string;

  @Prop({ type: String, default: '' })
  public value!: string;

  public dashboard = DashboardElement;

  public questionType: string | null = null;
  public bestPractise: string | null = null;
  public learningPotential: string | null = null;
  public filter: string | null = null;

  created() {
    this.questionType = this.init(DashboardElement.QUESTION_TYPE);
    this.bestPractise = this.init(DashboardElement.BEST_PRACTICE);
    this.learningPotential = this.init(DashboardElement.LEARNING_POTENTIAL);
    this.filter = this.init(DashboardElement.FILTER);
  }

  public discard() {
    const dialog = this.$el as HTMLBceDialogElement;
    dialog.hide();
    this.$emit('discard');
  }

  public onInput(event: Event) {
    const { value } = event.target as HTMLBceChipElement;
    if (this.questionType !== value) this.questionType = null;
    if (this.bestPractise !== value) this.bestPractise = null;
    if (this.learningPotential !== value) this.learningPotential = null;
    if (this.filter !== value) this.filter = null;
  }

  public submit() {
    const value =
      this.questionType ||
      this.bestPractise ||
      this.learningPotential ||
      this.filter ||
      undefined;

    this.$emit('submit', value);
  }

  private init(value: { values: { [key: string]: string } }) {
    return Object.keys(value.values).indexOf(this.value) >= 0
      ? this.value
      : null;
  }
}
